<script>
import { mapActions } from "vuex";
import UserService from "../common/mixins/User";

export default {
  mounted() {
    try {
      this.logout();
    } catch (error) {
      console.log("perform-logout", error);
    }
  },
  mixins: [UserService],
  methods: {
    ...mapActions("auth", {
      logoutUser: "logoutUser",
    }),
    async logout() {
      await this.logoutUser();
      this.$router.push("/login");
    },
  },
};
</script>
